import { CollectionInsights } from '@/components/features/CollectionInsights/CollectionInsights';
import { VolumeSortOption } from '@/components/features/CollectionInsights/types';
import { About, Intro, Partners, PopularCollections } from '@/components/features/Home';
import { MintingCollections } from '@/components/features/Home/MintingCollections';
import { ProjectSpotlight } from '@/components/features/Home/ProjectSpotlight';
import { SocialMediaLinks } from '@/components/shared/SocialMediaLinks';
import { CHAIN_STORAGE_KEY } from '@/context/ChainContextProvider';
import {
  CollectionType,
  Params as GetCollectionsParams,
  getCollections,
  useCollections,
} from '@/hooks/use-collections';
import { useMarketplaceChain } from '@/hooks/use-marketplace-chain';
import { db } from '@/lib/db';
import { getSSRChain } from '@/lib/reservoir/get-ssr-chain';
import { DefaultChain } from '@/utils/chains';
import { getSupportedChainById } from '@/utils/getChain';
import { routes } from '@/utils/routes';
import { Box, Container, Heading } from '@chakra-ui/react';
import { compareAddresses, isDefined } from '@onbeam/utils';
import { CollectionStatus, HighlightType } from '@prisma/client';
import ArrowRight from '@sphere/icons/components/ArrowRight';
import { ButtonLink } from '@sphere/ui';
import { QueryClient } from '@tanstack/react-query';
import { NextSeo } from 'next-seo';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { type GetStaticProps, type NextPage } from 'next/types';
import { rem } from 'polished';
import { Address, checksumAddress } from 'viem';

const Page: NextPage<Props> = ({
  mintingCollections,
  popularCollections,
  hotCollection,
  hotCollectionBackground,
}) => {
  const { t } = useTranslation('home');
  const marketplaceChain = useMarketplaceChain();
  const { isReady, pathname, replace } = useRouter();

  if (isReady && pathname === '/') {
    // Retrieve stored chain on first render
    const savedChainId = Number(localStorage.getItem(CHAIN_STORAGE_KEY) ?? DefaultChain.id);
    const chain = getSupportedChainById(savedChainId) ?? DefaultChain;
    replace(routes.home(chain?.routePrefix));
  }

  const { data: aboutCollectionData } = useCollections({
    sortBy: 'allTimeVolume',
  });

  return (
    <>
      <NextSeo title={t('page-title')} description={t('page-description')} />

      <Intro />

      <Box
        bg="blackBg"
        width="100%"
        borderTopLeftRadius="radius.32"
        borderTopRightRadius="radius.32"
        position="relative"
      >
        <Partners />

        {!!mintingCollections?.length && (
          <Box
            mt={{
              base: 'space.88',
              md: rem(160),
            }}
          >
            <MintingCollections collections={mintingCollections} />
          </Box>
        )}

        <Container
          variant="breakout"
          mt={{
            base: 'space.88',
            md: rem(160),
          }}
        >
          <Heading as="h3" size="lg" mb="space.24">
            {t('trending-insights.title')}
          </Heading>
          <CollectionInsights
            limitRows={5}
            disableFilters
            disablePagination
            initialSort={VolumeSortOption.ONE_DAY}
          >
            <ButtonLink
              mt="space.16"
              variant="secondary"
              href={routes.insights(marketplaceChain.routePrefix)}
              rightIcon={<ArrowRight boxSize={rem(15)} />}
            >
              {t('trending-insights.view-all')}
            </ButtonLink>
          </CollectionInsights>
        </Container>

        {hotCollection && (
          <ProjectSpotlight
            collection={hotCollection}
            background={hotCollectionBackground}
            mt={{
              base: 'space.88',
              md: rem(160),
            }}
          />
        )}

        {!!popularCollections?.length && (
          <PopularCollections
            collections={popularCollections}
            mt={{
              base: 'space.88',
              md: rem(160),
            }}
          />
        )}

        <About
          collections={aboutCollectionData?.collections}
          mt={{
            base: 'space.64',
            md: rem(200),
          }}
        />

        <SocialMediaLinks mt={{ base: 'space.88', md: rem(200) }} />
      </Box>
    </>
  );
};

type Props = {
  hotCollectionBackground?: string;
  hotCollection?: CollectionType;
  mintingCollections: CollectionType[];
  popularCollections: CollectionType[];
};

type Params = {
  chain: string;
};

const REVALIDATE = 60 * 5; // In seconds

export const getStaticProps: GetStaticProps<Props, Params> = async ctx => {
  const SSRClient = new QueryClient();
  const chain = getSSRChain(ctx);

  const highlightedCollections = await db.highlightedCollection.findMany({
    where: {
      chainId: chain.id,
    },
  });

  const hotData = highlightedCollections?.filter(item => item.types.includes(HighlightType.HOT))[0];
  // Retrieve all and later check for top 4 that are actually minting according to Reservoir
  const mintingData = highlightedCollections?.filter(item =>
    item.types.includes(HighlightType.MINTING),
  );
  // Only retrieve top 4
  const popularData = highlightedCollections
    ?.filter(item => item.types.includes(HighlightType.POPULAR))
    .slice(0, 4);

  const hotCollection = hotData?.address
    ? await db.collection.findUnique({
        where: {
          chainId_address_status: {
            chainId: chain.id,
            address: checksumAddress(hotData?.address as Address),
            status: CollectionStatus.PUBLISHED,
          },
        },
        select: {
          header: true,
        },
      })
    : null;

  const options: GetCollectionsParams = {
    contract: [
      hotData?.address,
      ...(mintingData ?? []).map(d => d.address),
      ...(popularData ?? []).map(d => d.address),
    ].filter(isDefined),
  };

  const collectionsResponse = await SSRClient.fetchQuery(getCollections(chain, options));

  const collectionsData = collectionsResponse?.collections?.map(collection => ({
    ...collection,
    id: checksumAddress(collection.id as Address),
  }));

  if (!collectionsResponse || !collectionsData) {
    return {
      props: {
        hotCollectionBackground: hotCollection?.header?.background?.src,
        hotCollection: undefined,
        mintingCollections: [],
        popularCollections: [],
      },
      revalidate: REVALIDATE,
    };
  }

  const hotCollectionData = collectionsData.find(c => compareAddresses(c.id, hotData?.address));
  const popularCollectionsData = collectionsData
    .filter(c => popularData?.map(d => checksumAddress(d.address as Address)).includes(c.id ?? ''))
    .filter(isDefined);
  const mintingCollectionsData = collectionsData
    ?.filter(c => mintingData?.map(d => checksumAddress(d.address as Address)).includes(c.id ?? ''))
    // Check whether the collection is actually minting according to Reservoir
    .map(collection => {
      const mintData = collection?.mintStages?.find(stage => stage.kind === 'public');
      if (!mintData) return undefined;
      return collection;
    })
    .filter(isDefined)
    .slice(0, 4);

  return {
    props: {
      hotCollectionBackground: hotCollection?.header?.background?.src,
      hotCollection: hotCollectionData,
      popularCollections: popularCollectionsData ?? [],
      mintingCollections: mintingCollectionsData ?? [],
    },
    revalidate: REVALIDATE,
  };
};

export default Page;
